$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.JHKbombbox{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  font-size: 14px;
  z-index: 99999999;
}
.bombbox{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: inherit;
}

.iframe{
  position: absolute;
  margin: auto;
  left:0;right:0;bottom:0;top:0;
  border-radius: 0;
  overflow: hidden;
  border: none;
}
.closedthis {
  background-image: url('#{$cdn_xcx_path}/pimg/close_btn_v2.png');
  cursor: pointer;
  position: fixed;
  top: 160px;
  right: 160px;
  z-index: 99;
  display: block;
  width: 28px;
  height: 28px;
  background-size: 100% 100%;
  background-position: center;
}

.fade-enter-active, .fade-leave-active {
  transition: all .5s !important;
  opacity: 1 !important;
}

.fade-enter, .fade-leave-to {
  opacity: 0 !important;
}
