@charset "UTF-8";
.font-regular {
  font-family: "SourceHanSansSC-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-medium {
  font-family: "SourceHanSansSC-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-bold {
  font-family: "SourceHanSansSC-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.grid {
  left: 0;
  top: 0;
  right: 0;
  bottom: 24px;
  position: absolute;
  cursor: pointer; }
  .grid:hover .wateButton {
    opacity: 1; }

.imgHover {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 16px;
  cursor: pointer;
  display: block; }
  .imgHover:hover {
    background: rgba(0, 0, 0, 0.1); }

.btns {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 16px;
  cursor: zoom-in;
  display: block;
  pointer-events: none; }

.imgWrapper {
  width: 100%;
  background: rgba(0, 0, 0, 0.04);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  overflow: hidden; }
  .imgWrapper .imgCropper {
    max-height: 500px;
    font-size: 0; }
  .imgWrapper .img {
    max-width: 236px; }

.wateButton {
  position: absolute;
  z-index: 10;
  opacity: 0;
  border-radius: 18px;
  color: #333;
  max-width: 132px;
  height: 32px;
  display: flex;
  text-decoration: none;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.9);
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.link {
  left: 12px;
  bottom: 12px; }
  .link:hover {
    color: #333; }

.linkIcon {
  margin-left: 12px;
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;
  fill: #333;
  stroke-width: 0;
  vertical-align: middle;
  flex: 0 0 auto; }

.linkText {
  margin-right: 12px;
  margin-left: 8px;
  display: flex; }
  .linkText span {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.share {
  width: 32px;
  right: 56px;
  bottom: 12px; }

.more {
  width: 32px;
  right: 12px;
  bottom: 12px; }

.fav {
  width: 53px;
  height: 40px;
  background: #e60023;
  border-radius: 22px;
  color: #fff;
  font-size: 12px;
  right: 12px;
  top: 12px; }
  .fav:hover {
    color: #fff; }

.free {
  width: 160px;
  max-width: 160px;
  height: 42px;
  background: #FF1A1A;
  border-radius: 4px;
  position: absolute;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  margin-left: -80px;
  margin-top: -21px;
  opacity: 0;
  font-weight: bold; }
  .free svg {
    margin-right: 8px;
    fill: #fff; }
