$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.block{
  padding-left: 58px;
  padding-right: 59px;
  position: sticky;
  top:108px;
  height:80px;
  overflow-y: hidden;
  z-index: 99;
  background: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  transform: scaleY(0);
}
.ready{
  pointer-events: auto;
  opacity: 1;
  transform: scaleY(1);
  width: 100vw;
}
.wrapper{
  padding-bottom: 12px;
  padding-top: 12px;
  height:80px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow: hidden;
}

.word{
  position: relative;
  height: 56px;
  min-width: 84px;
  white-space: nowrap;
  border-radius: 28px;
  display: flex;
  margin: 0 4px;

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
  .text{
    padding: 8px; color: #fff; font-size: 14px; font-weight: 700;
    position: relative;
    z-index: 1;
  }
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left:0;
    border-radius: 28px;
    background-color: rgba(0,0,0,0);

  }
  &:hover{
         &:after{
           background-color: rgba(0,0,0,0.1);
         }
       }
}
.button{
  width:34px; position: absolute; z-index: 2; height:80px; top:0; background: #fff;
  text-align: left;
  .arrow{
    position: relative; z-index: 5;
  }
  &::before{
    content: " ";
    width: 180px;
    height: 100%;
    position: absolute;
    top:0;
  }
  &:hover{
    background: #fff;
  }
}
.left{
  @extend .button;
  left:58px;
  top: 1px;
  &::before{
    left:34px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  }
}
.right{
  @extend .button;
  right:58px;
  top: 1px;
  text-align: right;
  &::before{
    right:34px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  }
}
