@charset "UTF-8";
.loginView {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: scroll; }
  .loginView .closeDialog {
    cursor: pointer;
    position: fixed;
    top: 160px;
    right: 160px;
    width: 28px;
    height: 28px;
    background-image: url("https://xcx-img.bigbigwork.cn/pimg/close_btn_v2.png");
    background-size: 100% 100%;
    background-position: 50%; }
  .loginView .loginWrapper {
    width: 706px;
    height: 564px;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    overflow: hidden; }
    .loginView .loginWrapper .characteristic {
      width: 266px;
      height: 100%;
      padding-top: 80px;
      background: url("https://dz-img.bigbigwork.cn/bbw/wxregister2.jpg") center center no-repeat;
      background-size: 100% 100%;
      position: relative; }
      .loginView .loginWrapper .characteristic.softBg {
        background-image: url("https://xcx.bigbigwork.com/pimg/software_dialogBg.jpg"); }
        .loginView .loginWrapper .characteristic.softBg > div {
          display: none; }
      .loginView .loginWrapper .characteristic .leftTopLogo {
        position: absolute;
        left: 30px;
        top: 19px; }
      .loginView .loginWrapper .characteristic .wrapperTop {
        opacity: .8; }
      .loginView .loginWrapper .characteristic .title {
        margin-bottom: 16px;
        text-align: center;
        height: 28px;
        line-height: 28px;
        font-size: 28px;
        font-weight: 400;
        color: #FFFFFF; }
      .loginView .loginWrapper .characteristic .subtitle {
        height: 16px;
        line-height: 16px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: left;
        margin-bottom: 60px;
        padding-left: 49px; }
        .loginView .loginWrapper .characteristic .subtitle.softTitle {
          opacity: .8;
          margin-left: auto;
          margin-right: auto;
          width: 190px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 0; }
          .loginView .loginWrapper .characteristic .subtitle.softTitle::before, .loginView .loginWrapper .characteristic .subtitle.softTitle::after {
            content: '';
            margin-top: 2px;
            width: 40px;
            height: 1px; }
          .loginView .loginWrapper .characteristic .subtitle.softTitle::before {
            background: linear-gradient(270deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%); }
          .loginView .loginWrapper .characteristic .subtitle.softTitle::after {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%); }
      .loginView .loginWrapper .characteristic .characterList .item {
        padding-left: 66px;
        position: relative;
        height: 14px;
        margin-bottom: 16px;
        line-height: 14px;
        font-size: 14px;
        color: #FFFFFF; }
        .loginView .loginWrapper .characteristic .characterList .item span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 44px;
          display: inline-block;
          width: 14px;
          height: 13px;
          background: url("https://dz-img.bigbigwork.cn/bbw/icon-gou.png") center center no-repeat;
          background-size: 100% 100%; }
      .loginView .loginWrapper .characteristic .secondaryTitle {
        opacity: .5;
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        width: 190px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 125px auto 0; }
        .loginView .loginWrapper .characteristic .secondaryTitle.softTitle2 {
          width: 210px; }
        .loginView .loginWrapper .characteristic .secondaryTitle::before, .loginView .loginWrapper .characteristic .secondaryTitle::after {
          content: '';
          margin-top: 2px;
          width: 40px;
          height: 1px; }
        .loginView .loginWrapper .characteristic .secondaryTitle::before {
          background: linear-gradient(270deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%); }
        .loginView .loginWrapper .characteristic .secondaryTitle::after {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%); }
  .loginView .loginBox {
    width: 440px;
    background-color: #ffffff;
    padding-top: 50px;
    position: relative; }
    .loginView .loginBox.softBox .wechatLogin .qrcodeBox {
      margin-bottom: 7px; }
    .loginView .loginBox .iconBox {
      text-align: center;
      font-size: 16px;
      color: #767676; }
      .loginView .loginBox .iconBox .rightH2 {
        font-size: 28px;
        color: #333333;
        margin-bottom: 25px;
        position: relative;
        top: 8px; }
      .loginView .loginBox .iconBox .rightH3 {
        margin-top: 12px;
        font-size: 16px; }
        .loginView .loginBox .iconBox .rightH3 > span:first-child {
          color: #333;
          margin-right: 4px; }
        .loginView .loginBox .iconBox .rightH3 > span:nth-child(2) {
          color: #FF4D4D; }
      .loginView .loginBox .iconBox img {
        vertical-align: bottom;
        width: 162px;
        height: 62px;
        margin-bottom: 15px;
        margin-left: 29px; }
      .loginView .loginBox .iconBox .subTitle {
        margin-bottom: 8px;
        line-height: 16px; }
      .loginView .loginBox .iconBox .userNum {
        line-height: 16px; }
      .loginView .loginBox .iconBox .softBlock .userNum {
        font-size: 14px; }
  .loginView .newLoginCss {
    padding-top: 40px; }
    .loginView .newLoginCss .iconBox img {
      margin-bottom: 0; }

.wechatLogin {
  width: 100%;
  padding-top: 24px; }
  .wechatLogin .qrcodeBox {
    width: 220px;
    height: 220px;
    overflow: hidden;
    margin: 0 auto 14px;
    position: relative; }
    .wechatLogin .qrcodeBox .qrcode img {
      width: 220px;
      height: 220px;
      vertical-align: bottom;
      background-color: #b4b4b4; }
    .wechatLogin .qrcodeBox .refresh {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all .3s ease-in;
      cursor: pointer;
      color: #fff;
      line-height: 220px;
      text-align: center;
      z-index: 30; }
  .wechatLogin .loginDes {
    padding-left: 123px;
    height: 24px;
    line-height: 24px;
    color: #AAAAAA;
    display: flex;
    align-items: center; }
    .wechatLogin .loginDes .wechatIcon {
      display: inline-block;
      background: url("https://xcx-img.bigbigwork.cn/pimg/wx%402x.png") center center no-repeat;
      background-size: 100% 100%;
      width: 24px;
      height: 24px;
      margin-right: 4px; }
  .wechatLogin .loginExplain {
    position: absolute;
    bottom: 20px;
    width: 100%;
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #AAAAAA;
    text-align: center; }
    .wechatLogin .loginExplain > a {
      color: #aaa;
      border-bottom: 1px solid #aaaaaa; }
      .wechatLogin .loginExplain > a:hover {
        color: #aaa; }

/* Ai扩大图单独修改 */
.loginView_Ai .loginBox .iconBox img {
  margin-left: 38px; }

.loginView_Ai .loginWrapper {
  margin-top: -25px;
  margin-left: 4px; }

.loginView_Ai .loginWrapper .characteristic .subtitle {
  margin-bottom: 46px; }

.loginView_Ai .loginWrapper .characteristic .secondaryTitle {
  margin-top: 110px; }
  .loginView_Ai .loginWrapper .characteristic .secondaryTitle::before, .loginView_Ai .loginWrapper .characteristic .secondaryTitle::after {
    margin-top: -3px; }

.loginView_Ai .loginBox .iconBox .subTitle {
  position: relative;
  left: 0;
  top: 2px; }

.loginView_Ai .loginDes {
  position: relative;
  top: 2px;
  left: -1px; }

.goLogin {
  text-align: center;
  margin-top: 14px;
  font-size: 12px;
  cursor: pointer; }
  .goLogin span:first-child {
    color: #aaaaaa; }
  .goLogin span:last-child {
    color: #666666;
    border-bottom: 1px solid #333;
    height: 19px;
    font-weight: 500; }

@media screen and (max-width: 1160px) {
  .loginView .closeDialog {
    right: 55px; } }
