$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.banner{
  height:80px; position: relative;z-index: 101; cursor: pointer;
  background-size: cover;
}
.link{
  position: absolute; left:0;right:0;top:0;bottom:0;
}
.content{
  display: flex; justify-content: center;align-items: center;height:80px;line-height: 80px;
}
.wrapper{
  display: flex; justify-content: center;align-items: center; height:80px;line-height: 80px;
  font-size:24px;
}
.buyVip{
  width:200px; height: 80px; background: url("#{$cdn_dz_path}/p/banner_button.png") no-repeat left center;
}
.top, .bottom{ display: block;height: 80px;}
.top{

}
.bottom{
  position: fixed; left: 0;right:0; bottom: 0;
}

.fadeup-enter-active, .fadeup-leave-active {
  transition: all .5s !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  opacity: 1 !important;
}

.fadeup-enter, .fadeup-leave-to {
  opacity: 0 !important;
  -webkit-transform: translate3d(0, 100%, 0) !important;
  -ms-transform: translate3d(0, 100%, 0) !important;
  transform: translate3d(0, 100%, 0) !important;
}
